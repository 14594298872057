export const successMessage = {
  META_MASK_CONNECT_SUCCESSFULLY: {
    title: 'meta mask',
    message: 'Connect to wallet successfully',
  },
  META_MASK_DISCONNECT_SUCCESSFULLY: {
    title: 'meta mask',
    message: 'Disconnect to wallet successfully',
  },
};
