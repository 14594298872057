import Bitcoin from 'assets/images/bitcoin.svg';

export const dataHoldings = [
  {
    key: 'treasury',
    title: 'Treasury Wallet',
    color: '#DBECFD',
    colorDark: ' linear-gradient(141.34deg, #2978F4 28.42%, #23ABF8 132.6%)',
  },
  {
    key: 'liquidity',
    title: 'Liquidity Wallet',
    color: '#E5E5FE',
    colorDark: '#327DD2',
  },
  {
    key: 'rewards',
    title: 'Rewards Wallet',
    color: '#D2FFDB',
    colorDark: '#2B91CF',
  },
  {
    key: 'dev_marketing',
    title: 'Dev/Marketing Wallet',
    color: '#DBECFD',
    colorDark: 'linear-gradient(141.34deg, #2978F4 28.42%, #23ABF8 132.6%)',
  },
  {
    key: 'reserve_rewards',
    title: 'Reserve Rewards Wallet',
    color: '#E5E5FE',
    colorDark: '#327DD2',
  },
];

export const investmentsData = [
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
  {
    icon: Bitcoin,
    // status: 'increase',
    name: 'Bitcoin',
    token_price: '38,955,45',
    our_holdings: 100,
    initial: '111,955,000',
    current_investment: '111,955,000',
  },
];
