export default [
  'Akan',
  'Amharic',
  'Arabic',
  'Assamese',
  'Awadhi',
  'Azerbaijani',
  'Balochi',
  'Belarusian',
  'Bengali',
  'Bhojpuri',
  'Burmese',
  'Cebuano',
  'Chewa',
  'Chhattisgarhi',
  'Chittagonian',
  'Czech',
  'Deccan',
  'Dhundhari',
  'Dutch',
  'English',
  'French',
  'Fula',
  'Gan',
  'German',
  'Greek',
  'Gujarati',
  'Hakka',
  'Haryanvi',
  'Hausa',
  'Hiligaynon',
  'Hindi',
  'Hmong',
  'Hungarian',
  'Igbo',
  'Ilocano',
  'Italian',
  'Japanese',
  'Javanese',
  'Jin',
  'Kannada',
  'Kazakh',
  'Khmer',
  'Kinyarwanda',
  'Kirundi',
  'Konkani',
  'Korean',
  'Kurdish',
  'Madurese',
  'Magahi',
  'Maithili',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Mandarin',
  'Marathi',
  'Marwari',
  'Min',
  'Mossi',
  'Nepali',
  'Odia',
  'Oromo',
  'Pashto',
  'Persian',
  'Polish',
  'Portuguese',
  'Punjabi',
  'Quechua',
  'Romanian',
  'Russian',
  'Saraiki',
  'Shona',
  'Sindhi',
  'Sinhala',
  'Somali',
  'Spanish',
  'Sundanese',
  'Swedish',
  'Sylheti',
  'Tagalog',
  'Tamil',
  'Telugu',
  'Thai',
  'Turkish',
  'Turkmen',
  'Ukrainian',
  'Urdu',
  'Uyghur',
  'Uzbek',
  'Vietnamese',
  'Wu',
  'Xhosa',
  'Xiang',
  'Yoruba',
  'Yue',
  'Zhuang',
  'Zulu',
];
