export default [
  'Aaren',
  'Aarika',
  'Abagael',
  'Abagail',
  'Abbe',
  'Abbey',
  'Abbi',
  'Abbie',
  'Abby',
  'Abbye',
  'Abigael',
  'Abigail',
  'Abigale',
  'Abra',
  'Ada',
  'Adah',
  'Adaline',
  'Adan',
  'Adara',
  'Adda',
  'Addi',
  'Addia',
  'Addie',
  'Addy',
  'Adel',
  'Adela',
  'Adelaida',
  'Adelaide',
  'Adele',
  'Adelheid',
  'Adelice',
  'Adelina',
  'Adelind',
  'Adeline',
  'Adella',
  'Adelle',
  'Adena',
  'Adey',
  'Adi',
  'Adiana',
  'Adina',
  'Adora',
  'Adore',
  'Adoree',
  'Adorne',
  'Adrea',
  'Adria',
  'Adriaens',
  'Adrian',
  'Adriana',
  'Adriane',
  'Adrianna',
  'Adrianne',
  'Adriena',
  'Adrienne',
  'Aeriel',
  'Aeriela',
  'Aeriell',
  'Afton',
  'Ag',
  'Agace',
  'Agata',
  'Agatha',
  'Agathe',
  'Aggi',
  'Aggie',
  'Aggy',
  'Agna',
  'Agnella',
  'Agnes',
  'Agnese',
  'Agnesse',
  'Agneta',
  'Agnola',
  'Agretha',
  'Aida',
  'Aidan',
  'Aigneis',
  'Aila',
  'Aile',
  'Ailee',
  'Aileen',
  'Ailene',
  'Ailey',
  'Aili',
  'Ailina',
  'Ailis',
  'Ailsun',
  'Ailyn',
  'Aime',
  'Aimee',
  'Aimil',
  'Aindrea',
  'Ainslee',
  'Ainsley',
  'Ainslie',
  'Ajay',
  'Alaine',
  'Alameda',
  'Alana',
  'Alanah',
  'Alane',
  'Alanna',
  'Alayne',
  'Alberta',
  'Albertina',
  'Albertine',
  'Albina',
  'Alecia',
  'Aleda',
  'Aleece',
  'Aleen',
  'Alejandra',
  'Alejandrina',
  'Alena',
  'Alene',
  'Alessandra',
  'Aleta',
  'Alethea',
  'Alex',
  'Alexa',
  'Alexandra',
  'Alexandrina',
  'Alexi',
  'Alexia',
  'Alexina',
  'Alexine',
  'Alexis',
  'Alfi',
  'Alfie',
  'Alfreda',
  'Alfy',
  'Ali',
  'Alia',
  'Alica',
  'Alice',
  'Alicea',
  'Alicia',
  'Alida',
  'Alidia',
  'Alie',
  'Alika',
  'Alikee',
  'Alina',
  'Aline',
  'Alis',
  'Alisa',
  'Alisha',
  'Alison',
  'Alissa',
  'Alisun',
  'Alix',
  'Aliza',
  'Alla',
  'Alleen',
  'Allegra',
  'Allene',
  'Alli',
  'Allianora',
  'Allie',
  'Allina',
  'Allis',
  'Allison',
  'Allissa',
  'Allix',
  'Allsun',
  'Allx',
  'Ally',
  'Allyce',
  'Allyn',
  'Allys',
  'Allyson',
  'Alma',
  'Almeda',
  'Almeria',
  'Almeta',
  'Almira',
  'Almire',
  'Aloise',
  'Aloisia',
  'Aloysia',
  'Alta',
  'Althea',
  'Alvera',
  'Alverta',
  'Alvina',
  'Alvinia',
  'Alvira',
  'Alyce',
  'Alyda',
  'Alys',
  'Alysa',
  'Alyse',
  'Alysia',
  'Alyson',
  'Alyss',
  'Alyssa',
  'Amabel',
  'Amabelle',
  'Amalea',
  'Amalee',
  'Amaleta',
  'Amalia',
  'Amalie',
  'Amalita',
  'Amalle',
  'Amanda',
  'Amandi',
  'Amandie',
  'Amandy',
  'Amara',
  'Amargo',
  'Amata',
  'Amber',
  'Amberly',
  'Ambur',
  'Ame',
  'Amelia',
  'Amelie',
  'Amelina',
  'Ameline',
  'Amelita',
  'Ami',
  'Amie',
  'Amii',
  'Amil',
  'Amitie',
  'Amity',
  'Ammamaria',
  'Amy',
  'Amye',
  'Ana',
  'Anabal',
  'Anabel',
  'Anabella',
  'Anabelle',
  'Analiese',
  'Analise',
  'Anallese',
  'Anallise',
  'Anastasia',
  'Anastasie',
  'Anastassia',
  'Anatola',
  'Andee',
  'Andeee',
  'Anderea',
  'Andi',
  'Andie',
  'Andra',
  'Andrea',
  'Andreana',
  'Andree',
  'Andrei',
  'Andria',
  'Andriana',
  'Andriette',
  'Andromache',
  'Andy',
  'Anestassia',
  'Anet',
  'Anett',
  'Anetta',
  'Anette',
  'Ange',
  'Angel',
  'Angela',
  'Angele',
  'Angelia',
  'Angelica',
  'Angelika',
  'Angelina',
  'Angeline',
  'Angelique',
  'Angelita',
  'Angelle',
  'Angie',
  'Angil',
  'Angy',
  'Ania',
  'Anica',
  'Anissa',
  'Anita',
  'Anitra',
  'Anjanette',
  'Anjela',
  'Ann',
  'Ann-marie',
  'Anna',
  'Anna-diana',
  'Anna-diane',
  'Anna-maria',
  'Annabal',
  'Annabel',
  'Annabela',
  'Annabell',
  'Annabella',
  'Annabelle',
  'Annadiana',
  'Annadiane',
  'Annalee',
  'Annaliese',
  'Annalise',
  'Annamaria',
  'Annamarie',
  'Anne',
  'Anne-corinne',
  'Anne-marie',
  'Annecorinne',
  'Anneliese',
  'Annelise',
  'Annemarie',
  'Annetta',
  'Annette',
  'Anni',
  'Annice',
  'Annie',
  'Annis',
  'Annissa',
  'Annmaria',
  'Annmarie',
  'Annnora',
  'Annora',
  'Anny',
  'Anselma',
  'Ansley',
  'Anstice',
  'Anthe',
  'Anthea',
  'Anthia',
  'Anthiathia',
  'Antoinette',
  'Antonella',
  'Antonetta',
  'Antonia',
  'Antonie',
  'Antonietta',
  'Antonina',
  'Anya',
  'Appolonia',
  'April',
  'Aprilette',
  'Ara',
  'Arabel',
  'Arabela',
  'Arabele',
  'Arabella',
  'Arabelle',
  'Arda',
  'Ardath',
  'Ardeen',
  'Ardelia',
  'Ardelis',
  'Ardella',
  'Ardelle',
  'Arden',
  'Ardene',
  'Ardenia',
  'Ardine',
  'Ardis',
  'Ardisj',
  'Ardith',
  'Ardra',
  'Ardyce',
  'Ardys',
  'Ardyth',
  'Aretha',
  'Ariadne',
  'Ariana',
  'Aridatha',
  'Ariel',
  'Ariela',
  'Ariella',
  'Arielle',
  'Arlana',
  'Arlee',
  'Arleen',
  'Arlen',
  'Arlena',
  'Arlene',
  'Arleta',
  'Arlette',
  'Arleyne',
  'Arlie',
  'Arliene',
  'Arlina',
  'Arlinda',
  'Arline',
  'Arluene',
  'Arly',
  'Arlyn',
  'Arlyne',
  'Aryn',
  'Ashely',
  'Ashia',
  'Ashien',
  'Ashil',
  'Ashla',
  'Ashlan',
  'Ashlee',
  'Ashleigh',
  'Ashlen',
  'Ashley',
  'Ashli',
  'Ashlie',
  'Ashly',
  'Asia',
  'Astra',
  'Astrid',
  'Astrix',
  'Atalanta',
  'Athena',
  'Athene',
  'Atlanta',
  'Atlante',
  'Auberta',
  'Aubine',
  'Aubree',
  'Aubrette',
  'Aubrey',
  'Aubrie',
  'Aubry',
  'Audi',
  'Audie',
  'Audra',
  'Audre',
  'Audrey',
  'Audrie',
  'Audry',
  'Audrye',
  'Audy',
  'Augusta',
  'Auguste',
  'Augustina',
  'Augustine',
  'Aundrea',
  'Aura',
  'Aurea',
  'Aurel',
  'Aurelea',
  'Aurelia',
  'Aurelie',
  'Auria',
  'Aurie',
  'Aurilia',
  'Aurlie',
  'Auroora',
  'Aurora',
  'Aurore',
  'Austin',
  'Austina',
  'Austine',
  'Ava',
  'Aveline',
  'Averil',
  'Averyl',
  'Avie',
  'Avis',
  'Aviva',
  'Avivah',
  'Avril',
  'Avrit',
  'Ayn',
  'Bab',
  'Babara',
  'Babb',
  'Babbette',
  'Babbie',
  'Babette',
  'Babita',
  'Babs',
  'Bambi',
  'Bambie',
  'Bamby',
  'Barb',
  'Barbabra',
  'Barbara',
  'Barbara-anne',
  'Barbaraanne',
  'Barbe',
  'Barbee',
  'Barbette',
  'Barbey',
  'Barbi',
  'Barbie',
  'Barbra',
  'Barby',
  'Bari',
  'Barrie',
  'Barry',
  'Basia',
  'Bathsheba',
  'Batsheva',
  'Bea',
  'Beatrice',
  'Beatrisa',
  'Beatrix',
  'Beatriz',
  'Bebe',
  'Becca',
  'Becka',
  'Becki',
  'Beckie',
  'Becky',
  'Bee',
  'Beilul',
  'Beitris',
  'Bekki',
  'Bel',
  'Belia',
  'Belicia',
  'Belinda',
  'Belita',
  'Bell',
  'Bella',
  'Bellanca',
  'Belle',
  'Bellina',
  'Belva',
  'Belvia',
  'Bendite',
  'Benedetta',
  'Benedicta',
  'Benedikta',
  'Benetta',
  'Benita',
  'Benni',
  'Bennie',
  'Benny',
  'Benoite',
  'Berenice',
  'Beret',
  'Berget',
  'Berna',
  'Bernadene',
  'Bernadette',
  'Bernadina',
  'Bernadine',
  'Bernardina',
  'Bernardine',
  'Bernelle',
  'Bernete',
  'Bernetta',
  'Bernette',
  'Berni',
  'Bernice',
  'Bernie',
  'Bernita',
  'Berny',
  'Berri',
  'Berrie',
  'Berry',
  'Bert',
  'Berta',
  'Berte',
  'Bertha',
  'Berthe',
  'Berti',
  'Bertie',
  'Bertina',
  'Bertine',
  'Berty',
  'Beryl',
  'Beryle',
  'Bess',
  'Bessie',
  'Bessy',
  'Beth',
  'Bethanne',
  'Bethany',
  'Bethena',
  'Bethina',
  'Betsey',
  'Betsy',
  'Betta',
  'Bette',
  'Bette-ann',
  'Betteann',
  'Betteanne',
  'Betti',
  'Bettina',
  'Bettine',
  'Betty',
  'Bettye',
  'Beulah',
  'Bev',
  'Beverie',
  'Beverlee',
  'Beverley',
  'Beverlie',
  'Beverly',
  'Bevvy',
  'Bianca',
  'Bianka',
  'Bibbie',
  'Bibby',
  'Bibbye',
  'Bibi',
  'Biddie',
  'Biddy',
  'Bidget',
  'Bili',
  'Bill',
  'Billi',
  'Billie',
  'Billy',
  'Billye',
  'Binni',
  'Binnie',
  'Binny',
  'Bird',
  'Birdie',
  'Birgit',
  'Birgitta',
  'Blair',
  'Blaire',
  'Blake',
  'Blakelee',
  'Blakeley',
  'Blanca',
  'Blanch',
  'Blancha',
  'Blanche',
  'Blinni',
  'Blinnie',
  'Blinny',
  'Bliss',
  'Blisse',
  'Blithe',
  'Blondell',
  'Blondelle',
  'Blondie',
  'Blondy',
  'Blythe',
  'Bobbe',
  'Bobbee',
  'Bobbette',
  'Bobbi',
  'Bobbie',
  'Bobby',
  'Bobbye',
  'Bobette',
  'Bobina',
  'Bobine',
  'Bobinette',
  'Bonita',
  'Bonnee',
  'Bonni',
  'Bonnibelle',
  'Bonnie',
  'Bonny',
  'Brana',
  'Brandais',
  'Brande',
  'Brandea',
  'Brandi',
  'Brandice',
  'Brandie',
  'Brandise',
  'Brandy',
  'Breanne',
  'Brear',
  'Bree',
  'Breena',
  'Bren',
  'Brena',
  'Brenda',
  'Brenn',
  'Brenna',
  'Brett',
  'Bria',
  'Briana',
  'Brianna',
  'Brianne',
  'Bride',
  'Bridget',
  'Bridgette',
  'Bridie',
  'Brier',
  'Brietta',
  'Brigid',
  'Brigida',
  'Brigit',
  'Brigitta',
  'Brigitte',
  'Brina',
  'Briney',
  'Brinn',
  'Brinna',
  'Briny',
  'Brit',
  'Brita',
  'Britney',
  'Britni',
  'Britt',
  'Britta',
  'Brittan',
  'Brittaney',
  'Brittani',
  'Brittany',
  'Britte',
  'Britteny',
  'Brittne',
  'Brittney',
  'Brittni',
  'Brook',
  'Brooke',
  'Brooks',
  'Brunhilda',
  'Brunhilde',
  'Bryana',
  'Bryn',
  'Bryna',
  'Brynn',
  'Brynna',
  'Brynne',
  'Buffy',
  'Bunni',
  'Bunnie',
  'Bunny',
  'Cacilia',
  'Cacilie',
  'Cahra',
  'Cairistiona',
  'Caitlin',
  'Caitrin',
  'Cal',
  'Calida',
  'Calla',
  'Calley',
  'Calli',
  'Callida',
  'Callie',
  'Cally',
  'Calypso',
  'Cam',
  'Camala',
  'Camel',
  'Camella',
  'Camellia',
  'Cami',
  'Camila',
  'Camile',
  'Camilla',
  'Camille',
  'Cammi',
  'Cammie',
  'Cammy',
  'Candace',
  'Candi',
  'Candice',
  'Candida',
  'Candide',
  'Candie',
  'Candis',
  'Candra',
  'Candy',
  'Caprice',
  'Cara',
  'Caralie',
  'Caren',
  'Carena',
  'Caresa',
  'Caressa',
  'Caresse',
  'Carey',
  'Cari',
  'Caria',
  'Carie',
  'Caril',
  'Carilyn',
  'Carin',
  'Carina',
  'Carine',
  'Cariotta',
  'Carissa',
  'Carita',
  'Caritta',
  'Carla',
  'Carlee',
  'Carleen',
  'Carlen',
  'Carlene',
  'Carley',
  'Carlie',
  'Carlin',
  'Carlina',
  'Carline',
  'Carlita',
  'Carlota',
  'Carlotta',
  'Carly',
  'Carlye',
  'Carlyn',
  'Carlynn',
  'Carlynne',
  'Carma',
  'Carmel',
  'Carmela',
  'Carmelia',
  'Carmelina',
  'Carmelita',
  'Carmella',
  'Carmelle',
  'Carmen',
  'Carmencita',
  'Carmina',
  'Carmine',
  'Carmita',
  'Carmon',
  'Caro',
  'Carol',
  'Carol-jean',
  'Carola',
  'Carolan',
  'Carolann',
  'Carole',
  'Carolee',
  'Carolin',
  'Carolina',
  'Caroline',
  'Caroljean',
  'Carolyn',
  'Carolyne',
  'Carolynn',
  'Caron',
  'Carree',
  'Carri',
  'Carrie',
  'Carrissa',
  'Carroll',
  'Carry',
  'Cary',
  'Caryl',
  'Caryn',
  'Casandra',
  'Casey',
  'Casi',
  'Casie',
  'Cass',
  'Cassandra',
  'Cassandre',
  'Cassandry',
  'Cassaundra',
  'Cassey',
  'Cassi',
  'Cassie',
  'Cassondra',
  'Cassy',
  'Catarina',
  'Cate',
  'Caterina',
  'Catha',
  'Catharina',
  'Catharine',
  'Cathe',
  'Cathee',
  'Catherin',
  'Catherina',
  'Catherine',
  'Cathi',
  'Cathie',
  'Cathleen',
  'Cathlene',
  'Cathrin',
  'Cathrine',
  'Cathryn',
  'Cathy',
  'Cathyleen',
  'Cati',
  'Catie',
  'Catina',
  'Catlaina',
  'Catlee',
  'Catlin',
  'Catrina',
  'Catriona',
  'Caty',
  'Caye',
  'Cayla',
  'Cecelia',
  'Cecil',
  'Cecile',
  'Ceciley',
  'Cecilia',
  'Cecilla',
  'Cecily',
  'Ceil',
  'Cele',
  'Celene',
  'Celesta',
  'Celeste',
  'Celestia',
  'Celestina',
  'Celestine',
  'Celestyn',
  'Celestyna',
  'Celia',
  'Celie',
  'Celina',
  'Celinda',
  'Celine',
  'Celinka',
  'Celisse',
  'Celka',
  'Celle',
  'Cesya',
  'Chad',
  'Chanda',
  'Chandal',
  'Chandra',
  'Channa',
  'Chantal',
  'Chantalle',
  'Charil',
  'Charin',
  'Charis',
  'Charissa',
  'Charisse',
  'Charita',
  'Charity',
  'Charla',
  'Charlean',
  'Charleen',
  'Charlena',
  'Charlene',
  'Charline',
  'Charlot',
  'Charlotta',
  'Charlotte',
  'Charmain',
  'Charmaine',
  'Charmane',
  'Charmian',
  'Charmine',
  'Charmion',
  'Charo',
  'Charyl',
  'Chastity',
  'Chelsae',
  'Chelsea',
  'Chelsey',
  'Chelsie',
  'Chelsy',
  'Cher',
  'Chere',
  'Cherey',
  'Cheri',
  'Cherianne',
  'Cherice',
  'Cherida',
  'Cherie',
  'Cherilyn',
  'Cherilynn',
  'Cherin',
  'Cherise',
  'Cherish',
  'Cherlyn',
  'Cherri',
  'Cherrita',
  'Cherry',
  'Chery',
  'Cherye',
  'Cheryl',
  'Cheslie',
  'Chiarra',
  'Chickie',
  'Chicky',
  'Chiquia',
  'Chiquita',
  'Chlo',
  'Chloe',
  'Chloette',
  'Chloris',
  'Chris',
  'Chrissie',
  'Chrissy',
  'Christa',
  'Christabel',
  'Christabella',
  'Christal',
  'Christalle',
  'Christan',
  'Christean',
  'Christel',
  'Christen',
  'Christi',
  'Christian',
  'Christiana',
  'Christiane',
  'Christie',
  'Christin',
  'Christina',
  'Christine',
  'Christy',
  'Christye',
  'Christyna',
  'Chrysa',
  'Chrysler',
  'Chrystal',
  'Chryste',
  'Chrystel',
  'Cicely',
  'Cicily',
  'Ciel',
  'Cilka',
  'Cinda',
  'Cindee',
  'Cindelyn',
  'Cinderella',
  'Cindi',
  'Cindie',
  'Cindra',
  'Cindy',
  'Cinnamon',
  'Cissiee',
  'Cissy',
  'Clair',
  'Claire',
  'Clara',
  'Clarabelle',
  'Clare',
  'Claresta',
  'Clareta',
  'Claretta',
  'Clarette',
  'Clarey',
  'Clari',
  'Claribel',
  'Clarice',
  'Clarie',
  'Clarinda',
  'Clarine',
  'Clarissa',
  'Clarisse',
  'Clarita',
  'Clary',
  'Claude',
  'Claudelle',
  'Claudetta',
  'Claudette',
  'Claudia',
  'Claudie',
  'Claudina',
  'Claudine',
  'Clea',
  'Clem',
  'Clemence',
  'Clementia',
  'Clementina',
  'Clementine',
  'Clemmie',
  'Clemmy',
  'Cleo',
  'Cleopatra',
  'Clerissa',
  'Clio',
  'Clo',
  'Cloe',
  'Cloris',
  'Clotilda',
  'Clovis',
  'Codee',
  'Codi',
  'Codie',
  'Cody',
  'Coleen',
  'Colene',
  'Coletta',
  'Colette',
  'Colleen',
  'Collen',
  'Collete',
  'Collette',
  'Collie',
  'Colline',
  'Colly',
  'Con',
  'Concettina',
  'Conchita',
  'Concordia',
  'Conni',
  'Connie',
  'Conny',
  'Consolata',
  'Constance',
  'Constancia',
  'Constancy',
  'Constanta',
  'Constantia',
  'Constantina',
  'Constantine',
  'Consuela',
  'Consuelo',
  'Cookie',
  'Cora',
  'Corabel',
  'Corabella',
  'Corabelle',
  'Coral',
  'Coralie',
  'Coraline',
  'Coralyn',
  'Cordelia',
  'Cordelie',
  'Cordey',
  'Cordi',
  'Cordie',
  'Cordula',
  'Cordy',
  'Coreen',
  'Corella',
  'Corenda',
  'Corene',
  'Coretta',
  'Corette',
  'Corey',
  'Cori',
  'Corie',
  'Corilla',
  'Corina',
  'Corine',
  'Corinna',
  'Corinne',
  'Coriss',
  'Corissa',
  'Corliss',
  'Corly',
  'Cornela',
  'Cornelia',
  'Cornelle',
  'Cornie',
  'Corny',
  'Correna',
  'Correy',
  'Corri',
  'Corrianne',
  'Corrie',
  'Corrina',
  'Corrine',
  'Corrinne',
  'Corry',
  'Cortney',
  'Cory',
  'Cosetta',
  'Cosette',
  'Costanza',
  'Courtenay',
  'Courtnay',
  'Courtney',
  'Crin',
  'Cris',
  'Crissie',
  'Crissy',
  'Crista',
  'Cristabel',
  'Cristal',
  'Cristen',
  'Cristi',
  'Cristie',
  'Cristin',
  'Cristina',
  'Cristine',
  'Cristionna',
  'Cristy',
  'Crysta',
  'Crystal',
  'Crystie',
  'Cthrine',
  'Cyb',
  'Cybil',
  'Cybill',
  'Cymbre',
  'Cynde',
  'Cyndi',
  'Cyndia',
  'Cyndie',
  'Cyndy',
  'Cynthea',
  'Cynthia',
  'Cynthie',
  'Cynthy',
  'Dacey',
  'Dacia',
  'Dacie',
  'Dacy',
  'Dael',
  'Daffi',
  'Daffie',
  'Daffy',
  'Dagmar',
  'Dahlia',
  'Daile',
  'Daisey',
  'Daisi',
  'Daisie',
  'Daisy',
  'Dale',
  'Dalenna',
  'Dalia',
  'Dalila',
  'Dallas',
  'Daloris',
  'Damara',
  'Damaris',
  'Damita',
  'Dana',
  'Danell',
  'Danella',
  'Danette',
  'Dani',
  'Dania',
  'Danica',
  'Danice',
  'Daniela',
  'Daniele',
  'Daniella',
  'Danielle',
  'Danika',
  'Danila',
  'Danit',
  'Danita',
  'Danna',
  'Danni',
  'Dannie',
  'Danny',
  'Dannye',
  'Danya',
  'Danyelle',
  'Danyette',
  'Daphene',
  'Daphna',
  'Daphne',
  'Dara',
  'Darb',
  'Darbie',
  'Darby',
  'Darcee',
  'Darcey',
  'Darci',
  'Darcie',
  'Darcy',
  'Darda',
  'Dareen',
  'Darell',
  'Darelle',
  'Dari',
  'Daria',
  'Darice',
  'Darla',
  'Darleen',
  'Darlene',
  'Darline',
  'Darlleen',
  'Daron',
  'Darrelle',
  'Darryl',
  'Darsey',
  'Darsie',
  'Darya',
  'Daryl',
  'Daryn',
  'Dasha',
  'Dasi',
  'Dasie',
  'Dasya',
  'Datha',
  'Daune',
  'Daveen',
  'Daveta',
  'Davida',
  'Davina',
  'Davine',
  'Davita',
  'Dawn',
  'Dawna',
  'Dayle',
  'Dayna',
  'Ddene',
  'De',
  'Deana',
  'Deane',
  'Deanna',
  'Deanne',
  'Deb',
  'Debbi',
  'Debbie',
  'Debby',
  'Debee',
  'Debera',
  'Debi',
  'Debor',
  'Debora',
  'Deborah',
  'Debra',
  'Dede',
  'Dedie',
  'Dedra',
  'Dee',
  'Deeann',
  'Deeanne',
  'Deedee',
  'Deena',
  'Deerdre',
  'Deeyn',
  'Dehlia',
  'Deidre',
  'Deina',
  'Deirdre',
  'Del',
  'Dela',
  'Delcina',
  'Delcine',
  'Delia',
  'Delila',
  'Delilah',
  'Delinda',
  'Dell',
  'Della',
  'Delly',
  'Delora',
  'Delores',
  'Deloria',
  'Deloris',
  'Delphine',
  'Delphinia',
  'Demeter',
  'Demetra',
  'Demetria',
  'Demetris',
  'Dena',
  'Deni',
  'Denice',
  'Denise',
  'Denna',
  'Denni',
  'Dennie',
  'Denny',
  'Deny',
  'Denys',
  'Denyse',
  'Deonne',
  'Desdemona',
  'Desirae',
  'Desiree',
  'Desiri',
  'Deva',
  'Devan',
  'Devi',
  'Devin',
  'Devina',
  'Devinne',
  'Devon',
  'Devondra',
  'Devonna',
  'Devonne',
  'Devora',
  'Di',
  'Diahann',
  'Dian',
  'Diana',
  'Diandra',
  'Diane',
  'Diane-marie',
  'Dianemarie',
  'Diann',
  'Dianna',
  'Dianne',
  'Diannne',
  'Didi',
  'Dido',
  'Diena',
  'Dierdre',
  'Dina',
  'Dinah',
  'Dinnie',
  'Dinny',
  'Dion',
  'Dione',
  'Dionis',
  'Dionne',
  'Dita',
  'Dix',
  'Dixie',
  'Dniren',
  'Dode',
  'Dodi',
  'Dodie',
  'Dody',
  'Doe',
  'Doll',
  'Dolley',
  'Dolli',
  'Dollie',
  'Dolly',
  'Dolores',
  'Dolorita',
  'Doloritas',
  'Domeniga',
  'Dominga',
  'Domini',
  'Dominica',
  'Dominique',
  'Dona',
  'Donella',
  'Donelle',
  'Donetta',
  'Donia',
  'Donica',
  'Donielle',
  'Donna',
  'Donnamarie',
  'Donni',
  'Donnie',
  'Donny',
  'Dora',
  'Doralia',
  'Doralin',
  'Doralyn',
  'Doralynn',
  'Doralynne',
  'Dore',
  'Doreen',
  'Dorelia',
  'Dorella',
  'Dorelle',
  'Dorena',
  'Dorene',
  'Doretta',
  'Dorette',
  'Dorey',
  'Dori',
  'Doria',
  'Dorian',
  'Dorice',
  'Dorie',
  'Dorine',
  'Doris',
  'Dorisa',
  'Dorise',
  'Dorita',
  'Doro',
  'Dorolice',
  'Dorolisa',
  'Dorotea',
  'Doroteya',
  'Dorothea',
  'Dorothee',
  'Dorothy',
  'Dorree',
  'Dorri',
  'Dorrie',
  'Dorris',
  'Dorry',
  'Dorthea',
  'Dorthy',
  'Dory',
  'Dosi',
  'Dot',
  'Doti',
  'Dotti',
  'Dottie',
  'Dotty',
  'Dre',
  'Dreddy',
  'Dredi',
  'Drona',
  'Dru',
  'Druci',
  'Drucie',
  'Drucill',
  'Drucy',
  'Drusi',
  'Drusie',
  'Drusilla',
  'Drusy',
  'Dulce',
  'Dulcea',
  'Dulci',
  'Dulcia',
  'Dulciana',
  'Dulcie',
  'Dulcine',
  'Dulcinea',
  'Dulcy',
  'Dulsea',
  'Dusty',
  'Dyan',
  'Dyana',
  'Dyane',
  'Dyann',
  'Dyanna',
  'Dyanne',
  'Dyna',
  'Dynah',
  'Eachelle',
  'Eada',
  'Eadie',
  'Eadith',
  'Ealasaid',
  'Eartha',
  'Easter',
  'Eba',
  'Ebba',
  'Ebonee',
  'Ebony',
  'Eda',
  'Eddi',
  'Eddie',
  'Eddy',
  'Ede',
  'Edee',
  'Edeline',
  'Eden',
  'Edi',
  'Edie',
  'Edin',
  'Edita',
  'Edith',
  'Editha',
  'Edithe',
  'Ediva',
  'Edna',
  'Edwina',
  'Edy',
  'Edyth',
  'Edythe',
  'Effie',
  'Eileen',
  'Eilis',
  'Eimile',
  'Eirena',
  'Ekaterina',
  'Elaina',
  'Elaine',
  'Elana',
  'Elane',
  'Elayne',
  'Elberta',
  'Elbertina',
  'Elbertine',
  'Eleanor',
  'Eleanora',
  'Eleanore',
  'Electra',
  'Eleen',
  'Elena',
  'Elene',
  'Eleni',
  'Elenore',
  'Eleonora',
  'Eleonore',
  'Elfie',
  'Elfreda',
  'Elfrida',
  'Elfrieda',
  'Elga',
  'Elianora',
  'Elianore',
  'Elicia',
  'Elie',
  'Elinor',
  'Elinore',
  'Elisa',
  'Elisabet',
  'Elisabeth',
  'Elisabetta',
  'Elise',
  'Elisha',
  'Elissa',
  'Elita',
  'Eliza',
  'Elizabet',
  'Elizabeth',
  'Elka',
  'Elke',
  'Ella',
  'Elladine',
  'Elle',
  'Ellen',
  'Ellene',
  'Ellette',
  'Elli',
  'Ellie',
  'Ellissa',
  'Elly',
  'Ellyn',
  'Ellynn',
  'Elmira',
  'Elna',
  'Elnora',
  'Elnore',
  'Eloisa',
  'Eloise',
  'Elonore',
  'Elora',
  'Elsa',
  'Elsbeth',
  'Else',
  'Elset',
  'Elsey',
  'Elsi',
  'Elsie',
  'Elsinore',
  'Elspeth',
  'Elsy',
  'Elva',
  'Elvera',
  'Elvina',
  'Elvira',
  'Elwira',
  'Elyn',
  'Elyse',
  'Elysee',
  'Elysha',
  'Elysia',
  'Elyssa',
  'Em',
  'Ema',
  'Emalee',
  'Emalia',
  'Emelda',
  'Emelia',
  'Emelina',
  'Emeline',
  'Emelita',
  'Emelyne',
  'Emera',
  'Emilee',
  'Emili',
  'Emilia',
  'Emilie',
  'Emiline',
  'Emily',
  'Emlyn',
  'Emlynn',
  'Emlynne',
  'Emma',
  'Emmalee',
  'Emmaline',
  'Emmalyn',
  'Emmalynn',
  'Emmalynne',
  'Emmeline',
  'Emmey',
  'Emmi',
  'Emmie',
  'Emmy',
  'Emmye',
  'Emogene',
  'Emyle',
  'Emylee',
  'Engracia',
  'Enid',
  'Enrica',
  'Enrichetta',
  'Enrika',
  'Enriqueta',
  'Eolanda',
  'Eolande',
  'Eran',
  'Erda',
  'Erena',
  'Erica',
  'Ericha',
  'Ericka',
  'Erika',
  'Erin',
  'Erina',
  'Erinn',
  'Erinna',
  'Erma',
  'Ermengarde',
  'Ermentrude',
  'Ermina',
  'Erminia',
  'Erminie',
  'Erna',
  'Ernaline',
  'Ernesta',
  'Ernestine',
  'Ertha',
  'Eryn',
  'Esma',
  'Esmaria',
  'Esme',
  'Esmeralda',
  'Essa',
  'Essie',
  'Essy',
  'Esta',
  'Estel',
  'Estele',
  'Estell',
  'Estella',
  'Estelle',
  'Ester',
  'Esther',
  'Estrella',
  'Estrellita',
  'Ethel',
  'Ethelda',
  'Ethelin',
  'Ethelind',
  'Etheline',
  'Ethelyn',
  'Ethyl',
  'Etta',
  'Etti',
  'Ettie',
  'Etty',
  'Eudora',
  'Eugenia',
  'Eugenie',
  'Eugine',
  'Eula',
  'Eulalie',
  'Eunice',
  'Euphemia',
  'Eustacia',
  'Eva',
  'Evaleen',
  'Evangelia',
  'Evangelin',
  'Evangelina',
  'Evangeline',
  'Evania',
  'Evanne',
  'Eve',
  'Eveleen',
  'Evelina',
  'Eveline',
  'Evelyn',
  'Evey',
  'Evie',
  'Evita',
  'Evonne',
  'Evvie',
  'Evvy',
  'Evy',
  'Eyde',
  'Eydie',
  'Ezmeralda',
  'Fae',
  'Faina',
  'Faith',
  'Fallon',
  'Fan',
  'Fanchette',
  'Fanchon',
  'Fancie',
  'Fancy',
  'Fanechka',
  'Fania',
  'Fanni',
  'Fannie',
  'Fanny',
  'Fanya',
  'Fara',
  'Farah',
  'Farand',
  'Farica',
  'Farra',
  'Farrah',
  'Farrand',
  'Faun',
  'Faunie',
  'Faustina',
  'Faustine',
  'Fawn',
  'Fawne',
  'Fawnia',
  'Fay',
  'Faydra',
  'Faye',
  'Fayette',
  'Fayina',
  'Fayre',
  'Fayth',
  'Faythe',
  'Federica',
  'Fedora',
  'Felecia',
  'Felicdad',
  'Felice',
  'Felicia',
  'Felicity',
  'Felicle',
  'Felipa',
  'Felisha',
  'Felita',
  'Feliza',
  'Fenelia',
  'Feodora',
  'Ferdinanda',
  'Ferdinande',
  'Fern',
  'Fernanda',
  'Fernande',
  'Fernandina',
  'Ferne',
  'Fey',
  'Fiann',
  'Fianna',
  'Fidela',
  'Fidelia',
  'Fidelity',
  'Fifi',
  'Fifine',
  'Filia',
  'Filide',
  'Filippa',
  'Fina',
  'Fiona',
  'Fionna',
  'Fionnula',
  'Fiorenze',
  'Fleur',
  'Fleurette',
  'Flo',
  'Flor',
  'Flora',
  'Florance',
  'Flore',
  'Florella',
  'Florence',
  'Florencia',
  'Florentia',
  'Florenza',
  'Florette',
  'Flori',
  'Floria',
  'Florida',
  'Florie',
  'Florina',
  'Florinda',
  'Floris',
  'Florri',
  'Florrie',
  'Florry',
  'Flory',
  'Flossi',
  'Flossie',
  'Flossy',
  'Flss',
  'Fran',
  'Francene',
  'Frances',
  'Francesca',
  'Francine',
  'Francisca',
  'Franciska',
  'Francoise',
  'Francyne',
  'Frank',
  'Frankie',
  'Franky',
  'Franni',
  'Frannie',
  'Franny',
  'Frayda',
  'Fred',
  'Freda',
  'Freddi',
  'Freddie',
  'Freddy',
  'Fredelia',
  'Frederica',
  'Fredericka',
  'Frederique',
  'Fredi',
  'Fredia',
  'Fredra',
  'Fredrika',
  'Freida',
  'Frieda',
  'Friederike',
  'Fulvia',
  'Gabbey',
  'Gabbi',
  'Gabbie',
  'Gabey',
  'Gabi',
  'Gabie',
  'Gabriel',
  'Gabriela',
  'Gabriell',
  'Gabriella',
  'Gabrielle',
  'Gabriellia',
  'Gabrila',
  'Gaby',
  'Gae',
  'Gael',
  'Gail',
  'Gale',
  'Galina',
  'Garland',
  'Garnet',
  'Garnette',
  'Gates',
  'Gavra',
  'Gavrielle',
  'Gay',
  'Gaye',
  'Gayel',
  'Gayla',
  'Gayle',
  'Gayleen',
  'Gaylene',
  'Gaynor',
  'Gelya',
  'Gena',
  'Gene',
  'Geneva',
  'Genevieve',
  'Genevra',
  'Genia',
  'Genna',
  'Genni',
  'Gennie',
  'Gennifer',
  'Genny',
  'Genovera',
  'Genvieve',
  'George',
  'Georgeanna',
  'Georgeanne',
  'Georgena',
  'Georgeta',
  'Georgetta',
  'Georgette',
  'Georgia',
  'Georgiana',
  'Georgianna',
  'Georgianne',
  'Georgie',
  'Georgina',
  'Georgine',
  'Geralda',
  'Geraldine',
  'Gerda',
  'Gerhardine',
  'Geri',
  'Gerianna',
  'Gerianne',
  'Gerladina',
  'Germain',
  'Germaine',
  'Germana',
  'Gerri',
  'Gerrie',
  'Gerrilee',
  'Gerry',
  'Gert',
  'Gerta',
  'Gerti',
  'Gertie',
  'Gertrud',
  'Gertruda',
  'Gertrude',
  'Gertrudis',
  'Gerty',
  'Giacinta',
  'Giana',
  'Gianina',
  'Gianna',
  'Gigi',
  'Gilberta',
  'Gilberte',
  'Gilbertina',
  'Gilbertine',
  'Gilda',
  'Gilemette',
  'Gill',
  'Gillan',
  'Gilli',
  'Gillian',
  'Gillie',
  'Gilligan',
  'Gilly',
  'Gina',
  'Ginelle',
  'Ginevra',
  'Ginger',
  'Ginni',
  'Ginnie',
  'Ginnifer',
  'Ginny',
  'Giorgia',
  'Giovanna',
  'Gipsy',
  'Giralda',
  'Gisela',
  'Gisele',
  'Gisella',
  'Giselle',
  'Giuditta',
  'Giulia',
  'Giulietta',
  'Giustina',
  'Gizela',
  'Glad',
  'Gladi',
  'Gladys',
  'Gleda',
  'Glen',
  'Glenda',
  'Glenine',
  'Glenn',
  'Glenna',
  'Glennie',
  'Glennis',
  'Glori',
  'Gloria',
  'Gloriana',
  'Gloriane',
  'Glory',
  'Glyn',
  'Glynda',
  'Glynis',
  'Glynnis',
  'Gnni',
  'Godiva',
  'Golda',
  'Goldarina',
  'Goldi',
  'Goldia',
  'Goldie',
  'Goldina',
  'Goldy',
  'Grace',
  'Gracia',
  'Gracie',
  'Grata',
  'Gratia',
  'Gratiana',
  'Gray',
  'Grayce',
  'Grazia',
  'Greer',
  'Greta',
  'Gretal',
  'Gretchen',
  'Grete',
  'Gretel',
  'Grethel',
  'Gretna',
  'Gretta',
  'Grier',
  'Griselda',
  'Grissel',
  'Guendolen',
  'Guenevere',
  'Guenna',
  'Guglielma',
  'Gui',
  'Guillema',
  'Guillemette',
  'Guinevere',
  'Guinna',
  'Gunilla',
  'Gus',
  'Gusella',
  'Gussi',
  'Gussie',
  'Gussy',
  'Gusta',
  'Gusti',
  'Gustie',
  'Gusty',
  'Gwen',
  'Gwendolen',
  'Gwendolin',
  'Gwendolyn',
  'Gweneth',
  'Gwenette',
  'Gwenneth',
  'Gwenni',
  'Gwennie',
  'Gwenny',
  'Gwenora',
  'Gwenore',
  'Gwyn',
  'Gwyneth',
  'Gwynne',
  'Gypsy',
  'Hadria',
  'Hailee',
  'Haily',
  'Haleigh',
  'Halette',
  'Haley',
  'Hali',
  'Halie',
  'Halimeda',
  'Halley',
  'Halli',
  'Hallie',
  'Hally',
  'Hana',
  'Hanna',
  'Hannah',
  'Hanni',
  'Hannie',
  'Hannis',
  'Hanny',
  'Happy',
  'Harlene',
  'Harley',
  'Harli',
  'Harlie',
  'Harmonia',
  'Harmonie',
  'Harmony',
  'Harri',
  'Harrie',
  'Harriet',
  'Harriett',
  'Harrietta',
  'Harriette',
  'Harriot',
  'Harriott',
  'Hatti',
  'Hattie',
  'Hatty',
  'Hayley',
  'Hazel',
  'Heath',
  'Heather',
  'Heda',
  'Hedda',
  'Heddi',
  'Heddie',
  'Hedi',
  'Hedvig',
  'Hedvige',
  'Hedwig',
  'Hedwiga',
  'Hedy',
  'Heida',
  'Heidi',
  'Heidie',
  'Helaina',
  'Helaine',
  'Helen',
  'Helen-elizabeth',
  'Helena',
  'Helene',
  'Helenka',
  'Helga',
  'Helge',
  'Helli',
  'Heloise',
  'Helsa',
  'Helyn',
  'Hendrika',
  'Henka',
  'Henrie',
  'Henrieta',
  'Henrietta',
  'Henriette',
  'Henryetta',
  'Hephzibah',
  'Hermia',
  'Hermina',
  'Hermine',
  'Herminia',
  'Hermione',
  'Herta',
  'Hertha',
  'Hester',
  'Hesther',
  'Hestia',
  'Hetti',
  'Hettie',
  'Hetty',
  'Hilary',
  'Hilda',
  'Hildagard',
  'Hildagarde',
  'Hilde',
  'Hildegaard',
  'Hildegarde',
  'Hildy',
  'Hillary',
  'Hilliary',
  'Hinda',
  'Holli',
  'Hollie',
  'Holly',
  'Holly-anne',
  'Hollyanne',
  'Honey',
  'Honor',
  'Honoria',
  'Hope',
  'Horatia',
  'Hortense',
  'Hortensia',
  'Hulda',
  'Hyacinth',
  'Hyacintha',
  'Hyacinthe',
  'Hyacinthia',
  'Hyacinthie',
  'Hynda',
  'Ianthe',
  'Ibbie',
  'Ibby',
  'Ida',
  'Idalia',
  'Idalina',
  'Idaline',
  'Idell',
  'Idelle',
  'Idette',
  'Ileana',
  'Ileane',
  'Ilene',
  'Ilise',
  'Ilka',
  'Illa',
  'Ilsa',
  'Ilse',
  'Ilysa',
  'Ilyse',
  'Ilyssa',
  'Imelda',
  'Imogen',
  'Imogene',
  'Imojean',
  'Ina',
  'Indira',
  'Ines',
  'Inesita',
  'Inessa',
  'Inez',
  'Inga',
  'Ingaberg',
  'Ingaborg',
  'Inge',
  'Ingeberg',
  'Ingeborg',
  'Inger',
  'Ingrid',
  'Ingunna',
  'Inna',
  'Iolande',
  'Iolanthe',
  'Iona',
  'Iormina',
  'Ira',
  'Irena',
  'Irene',
  'Irina',
  'Iris',
  'Irita',
  'Irma',
  'Isa',
  'Isabel',
  'Isabelita',
  'Isabella',
  'Isabelle',
  'Isadora',
  'Isahella',
  'Iseabal',
  'Isidora',
  'Isis',
  'Isobel',
  'Issi',
  'Issie',
  'Issy',
  'Ivett',
  'Ivette',
  'Ivie',
  'Ivonne',
  'Ivory',
  'Ivy',
  'Izabel',
  'Jacenta',
  'Jacinda',
  'Jacinta',
  'Jacintha',
  'Jacinthe',
  'Jackelyn',
  'Jacki',
  'Jackie',
  'Jacklin',
  'Jacklyn',
  'Jackquelin',
  'Jackqueline',
  'Jacky',
  'Jaclin',
  'Jaclyn',
  'Jacquelin',
  'Jacqueline',
  'Jacquelyn',
  'Jacquelynn',
  'Jacquenetta',
  'Jacquenette',
  'Jacquetta',
  'Jacquette',
  'Jacqui',
  'Jacquie',
  'Jacynth',
  'Jada',
  'Jade',
  'Jaime',
  'Jaimie',
  'Jaine',
  'Jami',
  'Jamie',
  'Jamima',
  'Jammie',
  'Jan',
  'Jana',
  'Janaya',
  'Janaye',
  'Jandy',
  'Jane',
  'Janean',
  'Janeczka',
  'Janeen',
  'Janel',
  'Janela',
  'Janella',
  'Janelle',
  'Janene',
  'Janenna',
  'Janessa',
  'Janet',
  'Janeta',
  'Janetta',
  'Janette',
  'Janeva',
  'Janey',
  'Jania',
  'Janice',
  'Janie',
  'Janifer',
  'Janina',
  'Janine',
  'Janis',
  'Janith',
  'Janka',
  'Janna',
  'Jannel',
  'Jannelle',
  'Janot',
  'Jany',
  'Jaquelin',
  'Jaquelyn',
  'Jaquenetta',
  'Jaquenette',
  'Jaquith',
  'Jasmin',
  'Jasmina',
  'Jasmine',
  'Jayme',
  'Jaymee',
  'Jayne',
  'Jaynell',
  'Jazmin',
  'Jean',
  'Jeana',
  'Jeane',
  'Jeanelle',
  'Jeanette',
  'Jeanie',
  'Jeanine',
  'Jeanna',
  'Jeanne',
  'Jeannette',
  'Jeannie',
  'Jeannine',
  'Jehanna',
  'Jelene',
  'Jemie',
  'Jemima',
  'Jemimah',
  'Jemmie',
  'Jemmy',
  'Jen',
  'Jena',
  'Jenda',
  'Jenelle',
  'Jeni',
  'Jenica',
  'Jeniece',
  'Jenifer',
  'Jeniffer',
  'Jenilee',
  'Jenine',
  'Jenn',
  'Jenna',
  'Jennee',
  'Jennette',
  'Jenni',
  'Jennica',
  'Jennie',
  'Jennifer',
  'Jennilee',
  'Jennine',
  'Jenny',
  'Jeralee',
  'Jere',
  'Jeri',
  'Jermaine',
  'Jerrie',
  'Jerrilee',
  'Jerrilyn',
  'Jerrine',
  'Jerry',
  'Jerrylee',
  'Jess',
  'Jessa',
  'Jessalin',
  'Jessalyn',
  'Jessamine',
  'Jessamyn',
  'Jesse',
  'Jesselyn',
  'Jessi',
  'Jessica',
  'Jessie',
  'Jessika',
  'Jessy',
  'Jewel',
  'Jewell',
  'Jewelle',
  'Jill',
  'Jillana',
  'Jillane',
  'Jillayne',
  'Jilleen',
  'Jillene',
  'Jilli',
  'Jillian',
  'Jillie',
  'Jilly',
  'Jinny',
  'Jo',
  'Jo-ann',
  'Jo-anne',
  'Joan',
  'Joana',
  'Joane',
  'Joanie',
  'Joann',
  'Joanna',
  'Joanne',
  'Joannes',
  'Jobey',
  'Jobi',
  'Jobie',
  'Jobina',
  'Joby',
  'Jobye',
  'Jobyna',
  'Jocelin',
  'Joceline',
  'Jocelyn',
  'Jocelyne',
  'Jodee',
  'Jodi',
  'Jodie',
  'Jody',
  'Joeann',
  'Joela',
  'Joelie',
  'Joell',
  'Joella',
  'Joelle',
  'Joellen',
  'Joelly',
  'Joellyn',
  'Joelynn',
  'Joete',
  'Joey',
  'Johanna',
  'Johannah',
  'Johna',
  'Johnath',
  'Johnette',
  'Johnna',
  'Joice',
  'Jojo',
  'Jolee',
  'Joleen',
  'Jolene',
  'Joletta',
  'Joli',
  'Jolie',
  'Joline',
  'Joly',
  'Jolyn',
  'Jolynn',
  'Jonell',
  'Joni',
  'Jonie',
  'Jonis',
  'Jordain',
  'Jordan',
  'Jordana',
  'Jordanna',
  'Jorey',
  'Jori',
  'Jorie',
  'Jorrie',
  'Jorry',
  'Joscelin',
  'Josee',
  'Josefa',
  'Josefina',
  'Josepha',
  'Josephina',
  'Josephine',
  'Josey',
  'Josi',
  'Josie',
  'Josselyn',
  'Josy',
  'Jourdan',
  'Joy',
  'Joya',
  'Joyan',
  'Joyann',
  'Joyce',
  'Joycelin',
  'Joye',
  'Jsandye',
  'Juana',
  'Juanita',
  'Judi',
  'Judie',
  'Judith',
  'Juditha',
  'Judy',
  'Judye',
  'Juieta',
  'Julee',
  'Juli',
  'Julia',
  'Juliana',
  'Juliane',
  'Juliann',
  'Julianna',
  'Julianne',
  'Julie',
  'Julienne',
  'Juliet',
  'Julieta',
  'Julietta',
  'Juliette',
  'Julina',
  'Juline',
  'Julissa',
  'Julita',
  'June',
  'Junette',
  'Junia',
  'Junie',
  'Junina',
  'Justina',
  'Justine',
  'Justinn',
  'Jyoti',
  'Kacey',
  'Kacie',
  'Kacy',
  'Kaela',
  'Kai',
  'Kaia',
  'Kaila',
  'Kaile',
  'Kailey',
  'Kaitlin',
  'Kaitlyn',
  'Kaitlynn',
  'Kaja',
  'Kakalina',
  'Kala',
  'Kaleena',
  'Kali',
  'Kalie',
  'Kalila',
  'Kalina',
  'Kalinda',
  'Kalindi',
  'Kalli',
  'Kally',
  'Kameko',
  'Kamila',
  'Kamilah',
  'Kamillah',
  'Kandace',
  'Kandy',
  'Kania',
  'Kanya',
  'Kara',
  'Kara-lynn',
  'Karalee',
  'Karalynn',
  'Kare',
  'Karee',
  'Karel',
  'Karen',
  'Karena',
  'Kari',
  'Karia',
  'Karie',
  'Karil',
  'Karilynn',
  'Karin',
  'Karina',
  'Karine',
  'Kariotta',
  'Karisa',
  'Karissa',
  'Karita',
  'Karla',
  'Karlee',
  'Karleen',
  'Karlen',
  'Karlene',
  'Karlie',
  'Karlotta',
  'Karlotte',
  'Karly',
  'Karlyn',
  'Karmen',
  'Karna',
  'Karol',
  'Karola',
  'Karole',
  'Karolina',
  'Karoline',
  'Karoly',
  'Karon',
  'Karrah',
  'Karrie',
  'Karry',
  'Kary',
  'Karyl',
  'Karylin',
  'Karyn',
  'Kasey',
  'Kass',
  'Kassandra',
  'Kassey',
  'Kassi',
  'Kassia',
  'Kassie',
  'Kat',
  'Kata',
  'Katalin',
  'Kate',
  'Katee',
  'Katerina',
  'Katerine',
  'Katey',
  'Kath',
  'Katha',
  'Katharina',
  'Katharine',
  'Katharyn',
  'Kathe',
  'Katherina',
  'Katherine',
  'Katheryn',
  'Kathi',
  'Kathie',
  'Kathleen',
  'Kathlin',
  'Kathrine',
  'Kathryn',
  'Kathryne',
  'Kathy',
  'Kathye',
  'Kati',
  'Katie',
  'Katina',
  'Katine',
  'Katinka',
  'Katleen',
  'Katlin',
  'Katrina',
  'Katrine',
  'Katrinka',
  'Katti',
  'Kattie',
  'Katuscha',
  'Katusha',
  'Katy',
  'Katya',
  'Kay',
  'Kaycee',
  'Kaye',
  'Kayla',
  'Kayle',
  'Kaylee',
  'Kayley',
  'Kaylil',
  'Kaylyn',
  'Keeley',
  'Keelia',
  'Keely',
  'Kelcey',
  'Kelci',
  'Kelcie',
  'Kelcy',
  'Kelila',
  'Kellen',
  'Kelley',
  'Kelli',
  'Kellia',
  'Kellie',
  'Kellina',
  'Kellsie',
  'Kelly',
  'Kellyann',
  'Kelsey',
  'Kelsi',
  'Kelsy',
  'Kendra',
  'Kendre',
  'Kenna',
  'Keri',
  'Keriann',
  'Kerianne',
  'Kerri',
  'Kerrie',
  'Kerrill',
  'Kerrin',
  'Kerry',
  'Kerstin',
  'Kesley',
  'Keslie',
  'Kessia',
  'Kessiah',
  'Ketti',
  'Kettie',
  'Ketty',
  'Kevina',
  'Kevyn',
  'Ki',
  'Kiah',
  'Kial',
  'Kiele',
  'Kiersten',
  'Kikelia',
  'Kiley',
  'Kim',
  'Kimberlee',
  'Kimberley',
  'Kimberli',
  'Kimberly',
  'Kimberlyn',
  'Kimbra',
  'Kimmi',
  'Kimmie',
  'Kimmy',
  'Kinna',
  'Kip',
  'Kipp',
  'Kippie',
  'Kippy',
  'Kira',
  'Kirbee',
  'Kirbie',
  'Kirby',
  'Kiri',
  'Kirsten',
  'Kirsteni',
  'Kirsti',
  'Kirstin',
  'Kirstyn',
  'Kissee',
  'Kissiah',
  'Kissie',
  'Kit',
  'Kitti',
  'Kittie',
  'Kitty',
  'Kizzee',
  'Kizzie',
  'Klara',
  'Klarika',
  'Klarrisa',
  'Konstance',
  'Konstanze',
  'Koo',
  'Kora',
  'Koral',
  'Koralle',
  'Kordula',
  'Kore',
  'Korella',
  'Koren',
  'Koressa',
  'Kori',
  'Korie',
  'Korney',
  'Korrie',
  'Korry',
  'Kris',
  'Krissie',
  'Krissy',
  'Krista',
  'Kristal',
  'Kristan',
  'Kriste',
  'Kristel',
  'Kristen',
  'Kristi',
  'Kristien',
  'Kristin',
  'Kristina',
  'Kristine',
  'Kristy',
  'Kristyn',
  'Krysta',
  'Krystal',
  'Krystalle',
  'Krystle',
  'Krystyna',
  'Kyla',
  'Kyle',
  'Kylen',
  'Kylie',
  'Kylila',
  'Kylynn',
  'Kym',
  'Kynthia',
  'Kyrstin',
  'Lacee',
  'Lacey',
  'Lacie',
  'Lacy',
  'Ladonna',
  'Laetitia',
  'Laina',
  'Lainey',
  'Lana',
  'Lanae',
  'Lane',
  'Lanette',
  'Laney',
  'Lani',
  'Lanie',
  'Lanita',
  'Lanna',
  'Lanni',
  'Lanny',
  'Lara',
  'Laraine',
  'Lari',
  'Larina',
  'Larine',
  'Larisa',
  'Larissa',
  'Lark',
  'Laryssa',
  'Latashia',
  'Latia',
  'Latisha',
  'Latrena',
  'Latrina',
  'Laura',
  'Lauraine',
  'Laural',
  'Lauralee',
  'Laure',
  'Lauree',
  'Laureen',
  'Laurel',
  'Laurella',
  'Lauren',
  'Laurena',
  'Laurene',
  'Lauretta',
  'Laurette',
  'Lauri',
  'Laurianne',
  'Laurice',
  'Laurie',
  'Lauryn',
  'Lavena',
  'Laverna',
  'Laverne',
  'Lavina',
  'Lavinia',
  'Lavinie',
  'Layla',
  'Layne',
  'Layney',
  'Lea',
  'Leah',
  'Leandra',
  'Leann',
  'Leanna',
  'Leanor',
  'Leanora',
  'Lebbie',
  'Leda',
  'Lee',
  'Leeann',
  'Leeanne',
  'Leela',
  'Leelah',
  'Leena',
  'Leesa',
  'Leese',
  'Legra',
  'Leia',
  'Leigh',
  'Leigha',
  'Leila',
  'Leilah',
  'Leisha',
  'Lela',
  'Lelah',
  'Leland',
  'Lelia',
  'Lena',
  'Lenee',
  'Lenette',
  'Lenka',
  'Lenna',
  'Lenora',
  'Lenore',
  'Leodora',
  'Leoine',
  'Leola',
  'Leoline',
  'Leona',
  'Leonanie',
  'Leone',
  'Leonelle',
  'Leonie',
  'Leonora',
  'Leonore',
  'Leontine',
  'Leontyne',
  'Leora',
  'Leshia',
  'Lesley',
  'Lesli',
  'Leslie',
  'Lesly',
  'Lesya',
  'Leta',
  'Lethia',
  'Leticia',
  'Letisha',
  'Letitia',
  'Letizia',
  'Letta',
  'Letti',
  'Lettie',
  'Letty',
  'Lexi',
  'Lexie',
  'Lexine',
  'Lexis',
  'Lexy',
  'Leyla',
  'Lezlie',
  'Lia',
  'Lian',
  'Liana',
  'Liane',
  'Lianna',
  'Lianne',
  'Lib',
  'Libbey',
  'Libbi',
  'Libbie',
  'Libby',
  'Licha',
  'Lida',
  'Lidia',
  'Liesa',
  'Lil',
  'Lila',
  'Lilah',
  'Lilas',
  'Lilia',
  'Lilian',
  'Liliane',
  'Lilias',
  'Lilith',
  'Lilla',
  'Lilli',
  'Lillian',
  'Lillis',
  'Lilllie',
  'Lilly',
  'Lily',
  'Lilyan',
  'Lin',
  'Lina',
  'Lind',
  'Linda',
  'Lindi',
  'Lindie',
  'Lindsay',
  'Lindsey',
  'Lindsy',
  'Lindy',
  'Linea',
  'Linell',
  'Linet',
  'Linette',
  'Linn',
  'Linnea',
  'Linnell',
  'Linnet',
  'Linnie',
  'Linzy',
  'Lira',
  'Lisa',
  'Lisabeth',
  'Lisbeth',
  'Lise',
  'Lisetta',
  'Lisette',
  'Lisha',
  'Lishe',
  'Lissa',
  'Lissi',
  'Lissie',
  'Lissy',
  'Lita',
  'Liuka',
  'Liv',
  'Liva',
  'Livia',
  'Livvie',
  'Livvy',
  'Livvyy',
  'Livy',
  'Liz',
  'Liza',
  'Lizabeth',
  'Lizbeth',
  'Lizette',
  'Lizzie',
  'Lizzy',
  'Loella',
  'Lois',
  'Loise',
  'Lola',
  'Loleta',
  'Lolita',
  'Lolly',
  'Lona',
  'Lonee',
  'Loni',
  'Lonna',
  'Lonni',
  'Lonnie',
  'Lora',
  'Lorain',
  'Loraine',
  'Loralee',
  'Loralie',
  'Loralyn',
  'Loree',
  'Loreen',
  'Lorelei',
  'Lorelle',
  'Loren',
  'Lorena',
  'Lorene',
  'Lorenza',
  'Loretta',
  'Lorette',
  'Lori',
  'Loria',
  'Lorianna',
  'Lorianne',
  'Lorie',
  'Lorilee',
  'Lorilyn',
  'Lorinda',
  'Lorine',
  'Lorita',
  'Lorna',
  'Lorne',
  'Lorraine',
  'Lorrayne',
  'Lorri',
  'Lorrie',
  'Lorrin',
  'Lorry',
  'Lory',
  'Lotta',
  'Lotte',
  'Lotti',
  'Lottie',
  'Lotty',
  'Lou',
  'Louella',
  'Louisa',
  'Louise',
  'Louisette',
  'Loutitia',
  'Lu',
  'Luce',
  'Luci',
  'Lucia',
  'Luciana',
  'Lucie',
  'Lucienne',
  'Lucila',
  'Lucilia',
  'Lucille',
  'Lucina',
  'Lucinda',
  'Lucine',
  'Lucita',
  'Lucky',
  'Lucretia',
  'Lucy',
  'Ludovika',
  'Luella',
  'Luelle',
  'Luisa',
  'Luise',
  'Lula',
  'Lulita',
  'Lulu',
  'Lura',
  'Lurette',
  'Lurleen',
  'Lurlene',
  'Lurline',
  'Lusa',
  'Luz',
  'Lyda',
  'Lydia',
  'Lydie',
  'Lyn',
  'Lynda',
  'Lynde',
  'Lyndel',
  'Lyndell',
  'Lyndsay',
  'Lyndsey',
  'Lyndsie',
  'Lyndy',
  'Lynea',
  'Lynelle',
  'Lynett',
  'Lynette',
  'Lynn',
  'Lynna',
  'Lynne',
  'Lynnea',
  'Lynnell',
  'Lynnelle',
  'Lynnet',
  'Lynnett',
  'Lynnette',
  'Lynsey',
  'Lyssa',
  'Mab',
  'Mabel',
  'Mabelle',
  'Mable',
  'Mada',
  'Madalena',
  'Madalyn',
  'Maddalena',
  'Maddi',
  'Maddie',
  'Maddy',
  'Madel',
  'Madelaine',
  'Madeleine',
  'Madelena',
  'Madelene',
  'Madelin',
  'Madelina',
  'Madeline',
  'Madella',
  'Madelle',
  'Madelon',
  'Madelyn',
  'Madge',
  'Madlen',
  'Madlin',
  'Madonna',
  'Mady',
  'Mae',
  'Maegan',
  'Mag',
  'Magda',
  'Magdaia',
  'Magdalen',
  'Magdalena',
  'Magdalene',
  'Maggee',
  'Maggi',
  'Maggie',
  'Maggy',
  'Mahala',
  'Mahalia',
  'Maia',
  'Maible',
  'Maiga',
  'Maighdiln',
  'Mair',
  'Maire',
  'Maisey',
  'Maisie',
  'Maitilde',
  'Mala',
  'Malanie',
  'Malena',
  'Malia',
  'Malina',
  'Malinda',
  'Malinde',
  'Malissa',
  'Malissia',
  'Mallissa',
  'Mallorie',
  'Mallory',
  'Malorie',
  'Malory',
  'Malva',
  'Malvina',
  'Malynda',
  'Mame',
  'Mamie',
  'Manda',
  'Mandi',
  'Mandie',
  'Mandy',
  'Manon',
  'Manya',
  'Mara',
  'Marabel',
  'Marcela',
  'Marcelia',
  'Marcella',
  'Marcelle',
  'Marcellina',
  'Marcelline',
  'Marchelle',
  'Marci',
  'Marcia',
  'Marcie',
  'Marcile',
  'Marcille',
  'Marcy',
  'Mareah',
  'Maren',
  'Marena',
  'Maressa',
  'Marga',
  'Margalit',
  'Margalo',
  'Margaret',
  'Margareta',
  'Margarete',
  'Margaretha',
  'Margarethe',
  'Margaretta',
  'Margarette',
  'Margarita',
  'Margaux',
  'Marge',
  'Margeaux',
  'Margery',
  'Marget',
  'Margette',
  'Margi',
  'Margie',
  'Margit',
  'Margo',
  'Margot',
  'Margret',
  'Marguerite',
  'Margy',
  'Mari',
  'Maria',
  'Mariam',
  'Marian',
  'Mariana',
  'Mariann',
  'Marianna',
  'Marianne',
  'Maribel',
  'Maribelle',
  'Maribeth',
  'Marice',
  'Maridel',
  'Marie',
  'Marie-ann',
  'Marie-jeanne',
  'Marieann',
  'Mariejeanne',
  'Mariel',
  'Mariele',
  'Marielle',
  'Mariellen',
  'Marietta',
  'Mariette',
  'Marigold',
  'Marijo',
  'Marika',
  'Marilee',
  'Marilin',
  'Marillin',
  'Marilyn',
  'Marin',
  'Marina',
  'Marinna',
  'Marion',
  'Mariquilla',
  'Maris',
  'Marisa',
  'Mariska',
  'Marissa',
  'Marita',
  'Maritsa',
  'Mariya',
  'Marj',
  'Marja',
  'Marje',
  'Marji',
  'Marjie',
  'Marjorie',
  'Marjory',
  'Marjy',
  'Marketa',
  'Marla',
  'Marlane',
  'Marleah',
  'Marlee',
  'Marleen',
  'Marlena',
  'Marlene',
  'Marley',
  'Marlie',
  'Marline',
  'Marlo',
  'Marlyn',
  'Marna',
  'Marne',
  'Marney',
  'Marni',
  'Marnia',
  'Marnie',
  'Marquita',
  'Marrilee',
  'Marris',
  'Marrissa',
  'Marsha',
  'Marsiella',
  'Marta',
  'Martelle',
  'Martguerita',
  'Martha',
  'Marthe',
  'Marthena',
  'Marti',
  'Martica',
  'Martie',
  'Martina',
  'Martita',
  'Marty',
  'Martynne',
  'Mary',
  'Marya',
  'Maryann',
  'Maryanna',
  'Maryanne',
  'Marybelle',
  'Marybeth',
  'Maryellen',
  'Maryjane',
  'Maryjo',
  'Maryl',
  'Marylee',
  'Marylin',
  'Marylinda',
  'Marylou',
  'Marylynne',
  'Maryrose',
  'Marys',
  'Marysa',
  'Masha',
  'Matelda',
  'Mathilda',
  'Mathilde',
  'Matilda',
  'Matilde',
  'Matti',
  'Mattie',
  'Matty',
  'Maud',
  'Maude',
  'Maudie',
  'Maura',
  'Maure',
  'Maureen',
  'Maureene',
  'Maurene',
  'Maurine',
  'Maurise',
  'Maurita',
  'Maurizia',
  'Mavis',
  'Mavra',
  'Max',
  'Maxi',
  'Maxie',
  'Maxine',
  'Maxy',
  'May',
  'Maybelle',
  'Maye',
  'Mead',
  'Meade',
  'Meagan',
  'Meaghan',
  'Meara',
  'Mechelle',
  'Meg',
  'Megan',
  'Megen',
  'Meggi',
  'Meggie',
  'Meggy',
  'Meghan',
  'Meghann',
  'Mehetabel',
  'Mei',
  'Mel',
  'Mela',
  'Melamie',
  'Melania',
  'Melanie',
  'Melantha',
  'Melany',
  'Melba',
  'Melesa',
  'Melessa',
  'Melicent',
  'Melina',
  'Melinda',
  'Melinde',
  'Melisa',
  'Melisande',
  'Melisandra',
  'Melisenda',
  'Melisent',
  'Melissa',
  'Melisse',
  'Melita',
  'Melitta',
  'Mella',
  'Melli',
  'Mellicent',
  'Mellie',
  'Mellisa',
  'Mellisent',
  'Melloney',
  'Melly',
  'Melodee',
  'Melodie',
  'Melody',
  'Melonie',
  'Melony',
  'Melosa',
  'Melva',
  'Mercedes',
  'Merci',
  'Mercie',
  'Mercy',
  'Meredith',
  'Meredithe',
  'Meridel',
  'Meridith',
  'Meriel',
  'Merilee',
  'Merilyn',
  'Meris',
  'Merissa',
  'Merl',
  'Merla',
  'Merle',
  'Merlina',
  'Merline',
  'Merna',
  'Merola',
  'Merralee',
  'Merridie',
  'Merrie',
  'Merrielle',
  'Merrile',
  'Merrilee',
  'Merrili',
  'Merrill',
  'Merrily',
  'Merry',
  'Mersey',
  'Meryl',
  'Meta',
  'Mia',
  'Micaela',
  'Michaela',
  'Michaelina',
  'Michaeline',
  'Michaella',
  'Michal',
  'Michel',
  'Michele',
  'Michelina',
  'Micheline',
  'Michell',
  'Michelle',
  'Micki',
  'Mickie',
  'Micky',
  'Midge',
  'Mignon',
  'Mignonne',
  'Miguela',
  'Miguelita',
  'Mikaela',
  'Mil',
  'Mildred',
  'Mildrid',
  'Milena',
  'Milicent',
  'Milissent',
  'Milka',
  'Milli',
  'Millicent',
  'Millie',
  'Millisent',
  'Milly',
  'Milzie',
  'Mimi',
  'Min',
  'Mina',
  'Minda',
  'Mindy',
  'Minerva',
  'Minetta',
  'Minette',
  'Minna',
  'Minnaminnie',
  'Minne',
  'Minni',
  'Minnie',
  'Minnnie',
  'Minny',
  'Minta',
  'Miquela',
  'Mira',
  'Mirabel',
  'Mirabella',
  'Mirabelle',
  'Miran',
  'Miranda',
  'Mireielle',
  'Mireille',
  'Mirella',
  'Mirelle',
  'Miriam',
  'Mirilla',
  'Mirna',
  'Misha',
  'Missie',
  'Missy',
  'Misti',
  'Misty',
  'Mitzi',
  'Modesta',
  'Modestia',
  'Modestine',
  'Modesty',
  'Moina',
  'Moira',
  'Moll',
  'Mollee',
  'Molli',
  'Mollie',
  'Molly',
  'Mommy',
  'Mona',
  'Monah',
  'Monica',
  'Monika',
  'Monique',
  'Mora',
  'Moreen',
  'Morena',
  'Morgan',
  'Morgana',
  'Morganica',
  'Morganne',
  'Morgen',
  'Moria',
  'Morissa',
  'Morna',
  'Moselle',
  'Moyna',
  'Moyra',
  'Mozelle',
  'Muffin',
  'Mufi',
  'Mufinella',
  'Muire',
  'Mureil',
  'Murial',
  'Muriel',
  'Murielle',
  'Myra',
  'Myrah',
  'Myranda',
  'Myriam',
  'Myrilla',
  'Myrle',
  'Myrlene',
  'Myrna',
  'Myrta',
  'Myrtia',
  'Myrtice',
  'Myrtie',
  'Myrtle',
  'Nada',
  'Nadean',
  'Nadeen',
  'Nadia',
  'Nadine',
  'Nadiya',
  'Nady',
  'Nadya',
  'Nalani',
  'Nan',
  'Nana',
  'Nananne',
  'Nance',
  'Nancee',
  'Nancey',
  'Nanci',
  'Nancie',
  'Nancy',
  'Nanete',
  'Nanette',
  'Nani',
  'Nanice',
  'Nanine',
  'Nannette',
  'Nanni',
  'Nannie',
  'Nanny',
  'Nanon',
  'Naoma',
  'Naomi',
  'Nara',
  'Nari',
  'Nariko',
  'Nat',
  'Nata',
  'Natala',
  'Natalee',
  'Natalie',
  'Natalina',
  'Nataline',
  'Natalya',
  'Natasha',
  'Natassia',
  'Nathalia',
  'Nathalie',
  'Natividad',
  'Natka',
  'Natty',
  'Neala',
  'Neda',
  'Nedda',
  'Nedi',
  'Neely',
  'Neila',
  'Neile',
  'Neilla',
  'Neille',
  'Nelia',
  'Nelie',
  'Nell',
  'Nelle',
  'Nelli',
  'Nellie',
  'Nelly',
  'Nerissa',
  'Nerita',
  'Nert',
  'Nerta',
  'Nerte',
  'Nerti',
  'Nertie',
  'Nerty',
  'Nessa',
  'Nessi',
  'Nessie',
  'Nessy',
  'Nesta',
  'Netta',
  'Netti',
  'Nettie',
  'Nettle',
  'Netty',
  'Nevsa',
  'Neysa',
  'Nichol',
  'Nichole',
  'Nicholle',
  'Nicki',
  'Nickie',
  'Nicky',
  'Nicol',
  'Nicola',
  'Nicole',
  'Nicolea',
  'Nicolette',
  'Nicoli',
  'Nicolina',
  'Nicoline',
  'Nicolle',
  'Nikaniki',
  'Nike',
  'Niki',
  'Nikki',
  'Nikkie',
  'Nikoletta',
  'Nikolia',
  'Nina',
  'Ninetta',
  'Ninette',
  'Ninnetta',
  'Ninnette',
  'Ninon',
  'Nissa',
  'Nisse',
  'Nissie',
  'Nissy',
  'Nita',
  'Nixie',
  'Noami',
  'Noel',
  'Noelani',
  'Noell',
  'Noella',
  'Noelle',
  'Noellyn',
  'Noelyn',
  'Noemi',
  'Nola',
  'Nolana',
  'Nolie',
  'Nollie',
  'Nomi',
  'Nona',
  'Nonah',
  'Noni',
  'Nonie',
  'Nonna',
  'Nonnah',
  'Nora',
  'Norah',
  'Norean',
  'Noreen',
  'Norene',
  'Norina',
  'Norine',
  'Norma',
  'Norri',
  'Norrie',
  'Norry',
  'Novelia',
  'Nydia',
  'Nyssa',
  'Octavia',
  'Odele',
  'Odelia',
  'Odelinda',
  'Odella',
  'Odelle',
  'Odessa',
  'Odetta',
  'Odette',
  'Odilia',
  'Odille',
  'Ofelia',
  'Ofella',
  'Ofilia',
  'Ola',
  'Olenka',
  'Olga',
  'Olia',
  'Olimpia',
  'Olive',
  'Olivette',
  'Olivia',
  'Olivie',
  'Oliy',
  'Ollie',
  'Olly',
  'Olva',
  'Olwen',
  'Olympe',
  'Olympia',
  'Olympie',
  'Ondrea',
  'Oneida',
  'Onida',
  'Oona',
  'Opal',
  'Opalina',
  'Opaline',
  'Ophelia',
  'Ophelie',
  'Ora',
  'Oralee',
  'Oralia',
  'Oralie',
  'Oralla',
  'Oralle',
  'Orel',
  'Orelee',
  'Orelia',
  'Orelie',
  'Orella',
  'Orelle',
  'Oriana',
  'Orly',
  'Orsa',
  'Orsola',
  'Ortensia',
  'Otha',
  'Othelia',
  'Othella',
  'Othilia',
  'Othilie',
  'Ottilie',
  'Page',
  'Paige',
  'Paloma',
  'Pam',
  'Pamela',
  'Pamelina',
  'Pamella',
  'Pammi',
  'Pammie',
  'Pammy',
  'Pandora',
  'Pansie',
  'Pansy',
  'Paola',
  'Paolina',
  'Papagena',
  'Pat',
  'Patience',
  'Patrica',
  'Patrice',
  'Patricia',
  'Patrizia',
  'Patsy',
  'Patti',
  'Pattie',
  'Patty',
  'Paula',
  'Paule',
  'Pauletta',
  'Paulette',
  'Pauli',
  'Paulie',
  'Paulina',
  'Pauline',
  'Paulita',
  'Pauly',
  'Pavia',
  'Pavla',
  'Pearl',
  'Pearla',
  'Pearle',
  'Pearline',
  'Peg',
  'Pegeen',
  'Peggi',
  'Peggie',
  'Peggy',
  'Pen',
  'Penelopa',
  'Penelope',
  'Penni',
  'Pennie',
  'Penny',
  'Pepi',
  'Pepita',
  'Peri',
  'Peria',
  'Perl',
  'Perla',
  'Perle',
  'Perri',
  'Perrine',
  'Perry',
  'Persis',
  'Pet',
  'Peta',
  'Petra',
  'Petrina',
  'Petronella',
  'Petronia',
  'Petronilla',
  'Petronille',
  'Petunia',
  'Phaedra',
  'Phaidra',
  'Phebe',
  'Phedra',
  'Phelia',
  'Phil',
  'Philipa',
  'Philippa',
  'Philippe',
  'Philippine',
  'Philis',
  'Phillida',
  'Phillie',
  'Phillis',
  'Philly',
  'Philomena',
  'Phoebe',
  'Phylis',
  'Phyllida',
  'Phyllis',
  'Phyllys',
  'Phylys',
  'Pia',
  'Pier',
  'Pierette',
  'Pierrette',
  'Pietra',
  'Piper',
  'Pippa',
  'Pippy',
  'Polly',
  'Pollyanna',
  'Pooh',
  'Poppy',
  'Portia',
  'Pris',
  'Prisca',
  'Priscella',
  'Priscilla',
  'Prissie',
  'Pru',
  'Prudence',
  'Prudi',
  'Prudy',
  'Prue',
  'Queenie',
  'Quentin',
  'Querida',
  'Quinn',
  'Quinta',
  'Quintana',
  'Quintilla',
  'Quintina',
  'Rachael',
  'Rachel',
  'Rachele',
  'Rachelle',
  'Rae',
  'Raeann',
  'Raf',
  'Rafa',
  'Rafaela',
  'Rafaelia',
  'Rafaelita',
  'Rahal',
  'Rahel',
  'Raina',
  'Raine',
  'Rakel',
  'Ralina',
  'Ramona',
  'Ramonda',
  'Rana',
  'Randa',
  'Randee',
  'Randene',
  'Randi',
  'Randie',
  'Randy',
  'Ranee',
  'Rani',
  'Rania',
  'Ranice',
  'Ranique',
  'Ranna',
  'Raphaela',
  'Raquel',
  'Raquela',
  'Rasia',
  'Rasla',
  'Raven',
  'Ray',
  'Raychel',
  'Raye',
  'Rayna',
  'Raynell',
  'Rayshell',
  'Rea',
  'Reba',
  'Rebbecca',
  'Rebe',
  'Rebeca',
  'Rebecca',
  'Rebecka',
  'Rebeka',
  'Rebekah',
  'Rebekkah',
  'Ree',
  'Reeba',
  'Reena',
  'Reeta',
  'Reeva',
  'Regan',
  'Reggi',
  'Reggie',
  'Regina',
  'Regine',
  'Reiko',
  'Reina',
  'Reine',
  'Remy',
  'Rena',
  'Renae',
  'Renata',
  'Renate',
  'Rene',
  'Renee',
  'Renell',
  'Renelle',
  'Renie',
  'Rennie',
  'Reta',
  'Retha',
  'Revkah',
  'Rey',
  'Reyna',
  'Rhea',
  'Rheba',
  'Rheta',
  'Rhetta',
  'Rhiamon',
  'Rhianna',
  'Rhianon',
  'Rhoda',
  'Rhodia',
  'Rhodie',
  'Rhody',
  'Rhona',
  'Rhonda',
  'Riane',
  'Riannon',
  'Rianon',
  'Rica',
  'Ricca',
  'Rici',
  'Ricki',
  'Rickie',
  'Ricky',
  'Riki',
  'Rikki',
  'Rina',
  'Risa',
  'Rita',
  'Riva',
  'Rivalee',
  'Rivi',
  'Rivkah',
  'Rivy',
  'Roana',
  'Roanna',
  'Roanne',
  'Robbi',
  'Robbie',
  'Robbin',
  'Robby',
  'Robbyn',
  'Robena',
  'Robenia',
  'Roberta',
  'Robin',
  'Robina',
  'Robinet',
  'Robinett',
  'Robinetta',
  'Robinette',
  'Robinia',
  'Roby',
  'Robyn',
  'Roch',
  'Rochell',
  'Rochella',
  'Rochelle',
  'Rochette',
  'Roda',
  'Rodi',
  'Rodie',
  'Rodina',
  'Rois',
  'Romola',
  'Romona',
  'Romonda',
  'Romy',
  'Rona',
  'Ronalda',
  'Ronda',
  'Ronica',
  'Ronna',
  'Ronni',
  'Ronnica',
  'Ronnie',
  'Ronny',
  'Roobbie',
  'Rora',
  'Rori',
  'Rorie',
  'Rory',
  'Ros',
  'Rosa',
  'Rosabel',
  'Rosabella',
  'Rosabelle',
  'Rosaleen',
  'Rosalia',
  'Rosalie',
  'Rosalind',
  'Rosalinda',
  'Rosalinde',
  'Rosaline',
  'Rosalyn',
  'Rosalynd',
  'Rosamond',
  'Rosamund',
  'Rosana',
  'Rosanna',
  'Rosanne',
  'Rose',
  'Roseann',
  'Roseanna',
  'Roseanne',
  'Roselia',
  'Roselin',
  'Roseline',
  'Rosella',
  'Roselle',
  'Rosemaria',
  'Rosemarie',
  'Rosemary',
  'Rosemonde',
  'Rosene',
  'Rosetta',
  'Rosette',
  'Roshelle',
  'Rosie',
  'Rosina',
  'Rosita',
  'Roslyn',
  'Rosmunda',
  'Rosy',
  'Row',
  'Rowe',
  'Rowena',
  'Roxana',
  'Roxane',
  'Roxanna',
  'Roxanne',
  'Roxi',
  'Roxie',
  'Roxine',
  'Roxy',
  'Roz',
  'Rozalie',
  'Rozalin',
  'Rozamond',
  'Rozanna',
  'Rozanne',
  'Roze',
  'Rozele',
  'Rozella',
  'Rozelle',
  'Rozina',
  'Rubetta',
  'Rubi',
  'Rubia',
  'Rubie',
  'Rubina',
  'Ruby',
  'Ruperta',
  'Ruth',
  'Ruthann',
  'Ruthanne',
  'Ruthe',
  'Ruthi',
  'Ruthie',
  'Ruthy',
  'Ryann',
  'Rycca',
  'Saba',
  'Sabina',
  'Sabine',
  'Sabra',
  'Sabrina',
  'Sacha',
  'Sada',
  'Sadella',
  'Sadie',
  'Sadye',
  'Saidee',
  'Sal',
  'Salaidh',
  'Sallee',
  'Salli',
  'Sallie',
  'Sally',
  'Sallyann',
  'Sallyanne',
  'Saloma',
  'Salome',
  'Salomi',
  'Sam',
  'Samantha',
  'Samara',
  'Samaria',
  'Sammy',
  'Sande',
  'Sandi',
  'Sandie',
  'Sandra',
  'Sandy',
  'Sandye',
  'Sapphira',
  'Sapphire',
  'Sara',
  'Sara-ann',
  'Saraann',
  'Sarah',
  'Sarajane',
  'Saree',
  'Sarena',
  'Sarene',
  'Sarette',
  'Sari',
  'Sarina',
  'Sarine',
  'Sarita',
  'Sascha',
  'Sasha',
  'Sashenka',
  'Saudra',
  'Saundra',
  'Savina',
  'Sayre',
  'Scarlet',
  'Scarlett',
  'Sean',
  'Seana',
  'Seka',
  'Sela',
  'Selena',
  'Selene',
  'Selestina',
  'Selia',
  'Selie',
  'Selina',
  'Selinda',
  'Seline',
  'Sella',
  'Selle',
  'Selma',
  'Sena',
  'Sephira',
  'Serena',
  'Serene',
  'Shae',
  'Shaina',
  'Shaine',
  'Shalna',
  'Shalne',
  'Shana',
  'Shanda',
  'Shandee',
  'Shandeigh',
  'Shandie',
  'Shandra',
  'Shandy',
  'Shane',
  'Shani',
  'Shanie',
  'Shanna',
  'Shannah',
  'Shannen',
  'Shannon',
  'Shanon',
  'Shanta',
  'Shantee',
  'Shara',
  'Sharai',
  'Shari',
  'Sharia',
  'Sharity',
  'Sharl',
  'Sharla',
  'Sharleen',
  'Sharlene',
  'Sharline',
  'Sharon',
  'Sharona',
  'Sharron',
  'Sharyl',
  'Shaun',
  'Shauna',
  'Shawn',
  'Shawna',
  'Shawnee',
  'Shay',
  'Shayla',
  'Shaylah',
  'Shaylyn',
  'Shaylynn',
  'Shayna',
  'Shayne',
  'Shea',
  'Sheba',
  'Sheela',
  'Sheelagh',
  'Sheelah',
  'Sheena',
  'Sheeree',
  'Sheila',
  'Sheila-kathryn',
  'Sheilah',
  'Shel',
  'Shela',
  'Shelagh',
  'Shelba',
  'Shelbi',
  'Shelby',
  'Shelia',
  'Shell',
  'Shelley',
  'Shelli',
  'Shellie',
  'Shelly',
  'Shena',
  'Sher',
  'Sheree',
  'Sheri',
  'Sherie',
  'Sherill',
  'Sherilyn',
  'Sherline',
  'Sherri',
  'Sherrie',
  'Sherry',
  'Sherye',
  'Sheryl',
  'Shina',
  'Shir',
  'Shirl',
  'Shirlee',
  'Shirleen',
  'Shirlene',
  'Shirley',
  'Shirline',
  'Shoshana',
  'Shoshanna',
  'Siana',
  'Sianna',
  'Sib',
  'Sibbie',
  'Sibby',
  'Sibeal',
  'Sibel',
  'Sibella',
  'Sibelle',
  'Sibilla',
  'Sibley',
  'Sibyl',
  'Sibylla',
  'Sibylle',
  'Sidoney',
  'Sidonia',
  'Sidonnie',
  'Sigrid',
  'Sile',
  'Sileas',
  'Silva',
  'Silvana',
  'Silvia',
  'Silvie',
  'Simona',
  'Simone',
  'Simonette',
  'Simonne',
  'Sindee',
  'Siobhan',
  'Sioux',
  'Siouxie',
  'Sisely',
  'Sisile',
  'Sissie',
  'Sissy',
  'Siusan',
  'Sofia',
  'Sofie',
  'Sondra',
  'Sonia',
  'Sonja',
  'Sonni',
  'Sonnie',
  'Sonnnie',
  'Sonny',
  'Sonya',
  'Sophey',
  'Sophi',
  'Sophia',
  'Sophie',
  'Sophronia',
  'Sorcha',
  'Sosanna',
  'Stace',
  'Stacee',
  'Stacey',
  'Staci',
  'Stacia',
  'Stacie',
  'Stacy',
  'Stafani',
  'Star',
  'Starla',
  'Starlene',
  'Starlin',
  'Starr',
  'Stefa',
  'Stefania',
  'Stefanie',
  'Steffane',
  'Steffi',
  'Steffie',
  'Stella',
  'Stepha',
  'Stephana',
  'Stephani',
  'Stephanie',
  'Stephannie',
  'Stephenie',
  'Stephi',
  'Stephie',
  'Stephine',
  'Stesha',
  'Stevana',
  'Stevena',
  'Stoddard',
  'Storm',
  'Stormi',
  'Stormie',
  'Stormy',
  'Sue',
  'Suellen',
  'Sukey',
  'Suki',
  'Sula',
  'Sunny',
  'Sunshine',
  'Susan',
  'Susana',
  'Susanetta',
  'Susann',
  'Susanna',
  'Susannah',
  'Susanne',
  'Susette',
  'Susi',
  'Susie',
  'Susy',
  'Suzann',
  'Suzanna',
  'Suzanne',
  'Suzette',
  'Suzi',
  'Suzie',
  'Suzy',
  'Sybil',
  'Sybila',
  'Sybilla',
  'Sybille',
  'Sybyl',
  'Sydel',
  'Sydelle',
  'Sydney',
  'Sylvia',
  'Tabatha',
  'Tabbatha',
  'Tabbi',
  'Tabbie',
  'Tabbitha',
  'Tabby',
  'Tabina',
  'Tabitha',
  'Taffy',
  'Talia',
  'Tallia',
  'Tallie',
  'Tallou',
  'Tallulah',
  'Tally',
  'Talya',
  'Talyah',
  'Tamar',
  'Tamara',
  'Tamarah',
  'Tamarra',
  'Tamera',
  'Tami',
  'Tamiko',
  'Tamma',
  'Tammara',
  'Tammi',
  'Tammie',
  'Tammy',
  'Tamqrah',
  'Tamra',
  'Tana',
  'Tandi',
  'Tandie',
  'Tandy',
  'Tanhya',
  'Tani',
  'Tania',
  'Tanitansy',
  'Tansy',
  'Tanya',
  'Tara',
  'Tarah',
  'Tarra',
  'Tarrah',
  'Taryn',
  'Tasha',
  'Tasia',
  'Tate',
  'Tatiana',
  'Tatiania',
  'Tatum',
  'Tawnya',
  'Tawsha',
  'Ted',
  'Tedda',
  'Teddi',
  'Teddie',
  'Teddy',
  'Tedi',
  'Tedra',
  'Teena',
  'Teirtza',
  'Teodora',
  'Tera',
  'Teresa',
  'Terese',
  'Teresina',
  'Teresita',
  'Teressa',
  'Teri',
  'Teriann',
  'Terra',
  'Terri',
  'Terrie',
  'Terrijo',
  'Terry',
  'Terrye',
  'Tersina',
  'Terza',
  'Tess',
  'Tessa',
  'Tessi',
  'Tessie',
  'Tessy',
  'Thalia',
  'Thea',
  'Theadora',
  'Theda',
  'Thekla',
  'Thelma',
  'Theo',
  'Theodora',
  'Theodosia',
  'Theresa',
  'Therese',
  'Theresina',
  'Theresita',
  'Theressa',
  'Therine',
  'Thia',
  'Thomasa',
  'Thomasin',
  'Thomasina',
  'Thomasine',
  'Tiena',
  'Tierney',
  'Tiertza',
  'Tiff',
  'Tiffani',
  'Tiffanie',
  'Tiffany',
  'Tiffi',
  'Tiffie',
  'Tiffy',
  'Tilda',
  'Tildi',
  'Tildie',
  'Tildy',
  'Tillie',
  'Tilly',
  'Tim',
  'Timi',
  'Timmi',
  'Timmie',
  'Timmy',
  'Timothea',
  'Tina',
  'Tine',
  'Tiphani',
  'Tiphanie',
  'Tiphany',
  'Tish',
  'Tisha',
  'Tobe',
  'Tobey',
  'Tobi',
  'Toby',
  'Tobye',
  'Toinette',
  'Toma',
  'Tomasina',
  'Tomasine',
  'Tomi',
  'Tommi',
  'Tommie',
  'Tommy',
  'Toni',
  'Tonia',
  'Tonie',
  'Tony',
  'Tonya',
  'Tonye',
  'Tootsie',
  'Torey',
  'Tori',
  'Torie',
  'Torrie',
  'Tory',
  'Tova',
  'Tove',
  'Tracee',
  'Tracey',
  'Traci',
  'Tracie',
  'Tracy',
  'Trenna',
  'Tresa',
  'Trescha',
  'Tressa',
  'Tricia',
  'Trina',
  'Trish',
  'Trisha',
  'Trista',
  'Trix',
  'Trixi',
  'Trixie',
  'Trixy',
  'Truda',
  'Trude',
  'Trudey',
  'Trudi',
  'Trudie',
  'Trudy',
  'Trula',
  'Tuesday',
  'Twila',
  'Twyla',
  'Tybi',
  'Tybie',
  'Tyne',
  'Ula',
  'Ulla',
  'Ulrica',
  'Ulrika',
  'Ulrikaumeko',
  'Ulrike',
  'Umeko',
  'Una',
  'Ursa',
  'Ursala',
  'Ursola',
  'Ursula',
  'Ursulina',
  'Ursuline',
  'Uta',
  'Val',
  'Valaree',
  'Valaria',
  'Vale',
  'Valeda',
  'Valencia',
  'Valene',
  'Valenka',
  'Valentia',
  'Valentina',
  'Valentine',
  'Valera',
  'Valeria',
  'Valerie',
  'Valery',
  'Valerye',
  'Valida',
  'Valina',
  'Valli',
  'Vallie',
  'Vally',
  'Valma',
  'Valry',
  'Van',
  'Vanda',
  'Vanessa',
  'Vania',
  'Vanna',
  'Vanni',
  'Vannie',
  'Vanny',
  'Vanya',
  'Veda',
  'Velma',
  'Velvet',
  'Venita',
  'Venus',
  'Vera',
  'Veradis',
  'Vere',
  'Verena',
  'Verene',
  'Veriee',
  'Verile',
  'Verina',
  'Verine',
  'Verla',
  'Verna',
  'Vernice',
  'Veronica',
  'Veronika',
  'Veronike',
  'Veronique',
  'Vevay',
  'Vi',
  'Vicki',
  'Vickie',
  'Vicky',
  'Victoria',
  'Vida',
  'Viki',
  'Vikki',
  'Vikky',
  'Vilhelmina',
  'Vilma',
  'Vin',
  'Vina',
  'Vinita',
  'Vinni',
  'Vinnie',
  'Vinny',
  'Viola',
  'Violante',
  'Viole',
  'Violet',
  'Violetta',
  'Violette',
  'Virgie',
  'Virgina',
  'Virginia',
  'Virginie',
  'Vita',
  'Vitia',
  'Vitoria',
  'Vittoria',
  'Viv',
  'Viva',
  'Vivi',
  'Vivia',
  'Vivian',
  'Viviana',
  'Vivianna',
  'Vivianne',
  'Vivie',
  'Vivien',
  'Viviene',
  'Vivienne',
  'Viviyan',
  'Vivyan',
  'Vivyanne',
  'Vonni',
  'Vonnie',
  'Vonny',
  'Vyky',
  'Wallie',
  'Wallis',
  'Walliw',
  'Wally',
  'Waly',
  'Wanda',
  'Wandie',
  'Wandis',
  'Waneta',
  'Wanids',
  'Wenda',
  'Wendeline',
  'Wendi',
  'Wendie',
  'Wendy',
  'Wendye',
  'Wenona',
  'Wenonah',
  'Whitney',
  'Wileen',
  'Wilhelmina',
  'Wilhelmine',
  'Wilie',
  'Willa',
  'Willabella',
  'Willamina',
  'Willetta',
  'Willette',
  'Willi',
  'Willie',
  'Willow',
  'Willy',
  'Willyt',
  'Wilma',
  'Wilmette',
  'Wilona',
  'Wilone',
  'Wilow',
  'Windy',
  'Wini',
  'Winifred',
  'Winna',
  'Winnah',
  'Winne',
  'Winni',
  'Winnie',
  'Winnifred',
  'Winny',
  'Winona',
  'Winonah',
  'Wren',
  'Wrennie',
  'Wylma',
  'Wynn',
  'Wynne',
  'Wynnie',
  'Wynny',
  'Xaviera',
  'Xena',
  'Xenia',
  'Xylia',
  'Xylina',
  'Yalonda',
  'Yasmeen',
  'Yasmin',
  'Yelena',
  'Yetta',
  'Yettie',
  'Yetty',
  'Yevette',
  'Ynes',
  'Ynez',
  'Yoko',
  'Yolanda',
  'Yolande',
  'Yolane',
  'Yolanthe',
  'Yoshi',
  'Yoshiko',
  'Yovonnda',
  'Ysabel',
  'Yvette',
  'Yvonne',
  'Zabrina',
  'Zahara',
  'Zandra',
  'Zaneta',
  'Zara',
  'Zarah',
  'Zaria',
  'Zarla',
  'Zea',
  'Zelda',
  'Zelma',
  'Zena',
  'Zenia',
  'Zia',
  'Zilvia',
  'Zita',
  'Zitella',
  'Zoe',
  'Zola',
  'Zonda',
  'Zondra',
  'Zonnya',
  'Zora',
  'Zorah',
  'Zorana',
  'Zorina',
  'Zorine',
  'Zsazsa',
  'Zulema',
  'Zuzana',
];
